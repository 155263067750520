<template>
    <div class="verify_account">
        <div class="area_content" v-if="loader">
            <div class="logo">
                <img src="@/assets/images/logo2.svg" alt="Logo">
            </div>
            <h2>Payment verified successfully!</h2>
            <h5>Please wait while we are activating your account, we will send you an email confirmation with the password.</h5>
            <button disabled class="primary_btn mt-5">
                <i class="fa fa-spin fa-spinner"></i>&nbsp; Activating Account
            </button>
        </div>
        <div class="area_content" v-else-if="!loader && activated">
            <div class="logo">
                <img src="@/assets/images/logo2.svg" alt="Logo">
            </div>
            <h2>Congratulations! You have successfully started your trial. Redirecting you to your dashboard now...</h2>
            <h5>Please check your email we have sent a confirmation with the password</h5>
            <router-link class="primary_btn mt-5" :to="{ name: 'Login' }">Log in</router-link>
        </div>
        <div class="area_content" v-else>
            <div class="logo">
                <img src="@/assets/images/logo2.svg" alt="Logo">
            </div>
            <h2>Congratulations! You have successfully started your trial. Redirecting you to your dashboard now...</h2>
            <h5>Please check your email we have sent a confirmation with the password</h5>
            <router-link class="primary_btn mt-5" :to="{ name: 'Login' }">Log in</router-link>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    import axios from '@/services/axios'
    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Registration Finish',

        data () {
            return {
                billingApi: process.env.VUE_APP_BILLING_API_URL,
                loader: false,
                activated: false,
            };
        },

        computed: mapState({
            acountDetails: state => state.registerModule.acountDetails,
            isAccountActivated: state => state.registerModule.isAccountActivated,
            user: state => state.authModule.user,
        }),

        mounted () {
            const vm = this;

            if (!vm.isAccountActivated) {
                vm.retrieveSession(vm.$route.query.session_id);
            }
        },

        methods: {
            ...mapActions({
                activateAccount: 'registerModule/activateAccount',
            }),

            retrieveSession (sessionId) {
                const vm = this;
                vm.loader = true;

                axios.get(`${vm.billingApi}/stripe/${sessionId}/session-retrieve`, {}, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${vm.user.access_token}`,
                    },
                }).then((resp) => {
                    vm.handleActivateAccount(resp.data.subscription);
                }).catch((err) => {
                    vm.loader = false;
                });
            },

            handleActivateAccount (subscription) {
                const vm = this;
                const params = { subscription, token: vm.$route.params.token };

                vm.loader = true;

                vm.activateAccount(params).then((result) => {
                    if (result) {
                        vm.activated = true;

                        const gist = window.gist;
                        const user = result.user;

                        if (gist && process.env.VUE_APP_ENV == 'production'  && user.email && !user.email.includes('@onboardme.io')) {
                            gist.chat('shutdown');

                            setTimeout(function () {
                                gist.identify(user.id, {
                                    email: user.email,
                                    name: user.full_name,
                                    tags: user.plan_name,
                                });

                                vm.$router.push({ name: 'AccountSetup' });
                                // vm.$router.push({ name: 'SetupGuideIndex' });
                            }, 1000);
                        } else {
                            vm.$router.push({ name: 'AccountSetup' });
                            // vm.$router.push({ name: 'SetupGuideIndex' });
                        }
                    }
                    vm.loader = false;
                });
            },
        },
    };
</script>

<style scoped>
    .verify_account {
        height: 100vh;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .verify_account .area_content {
        max-width: 500px;
        width: 100%;
        text-align: center;
    }

    .verify_account .area_content .logo {
        margin: 0 0 40px;
    }

    .verify_account .area_content .logo img {
        max-width: 200px;
        height: auto;
        width: 100%;
    }

    .verify_account h2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 600;
        color: #121525;
        margin-bottom: 10px;
    }

    .verify_account h5 {
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 500;
    }

    .verify_account .verify_form {
        border: 1px solid #e9e9e9;
        background: #f5f5f5;
        border-radius: 8px;
        padding: 20px 40px 45px 40px;
        margin-top: 30px;
    }

    .verify_account .verify_form .field_wpr {
        background: #fff;
    }

    .verify_account .more_action {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
    }

    .verify_account .more_action li {
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 400;
        cursor: pointer;
    }

    .verify_account .more_action li i {
        margin-right: 7px;
    }

    .verify_account .more_action li a {
        text-decoration: none;
        color: #5a5a5a;
    }

    .verify_account .primary_btn {
        position: relative;
        height: 50px;
        cursor: pointer;
        text-decoration: none;
    }

    .verify_account .primary_btn span {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
    }

    .verify_account .primary_btn .suffix {
        position: absolute;
        right: 70px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
    }
</style>
